import React from 'react';
import { useLocation } from 'react-router-dom';
import Layout, { Content, Footer } from 'antd/lib/layout/layout';

import { LOGIN_PAGE, SHIPPING_PAGE, SIGNUP_PAGE } from '../../settings/constants';
import { ContainerWrapper } from './layoutStyle';
import HeaderMenu from './headerMenu';
import useNotification from 'antd/es/notification/useNotification';
import useMessage from 'antd/es/message/useMessage';



const LayoutProvider = ({ children }) => {
    const location = useLocation();
    const [ api ,contextHolder ] = useNotification();
    const [ msg, contextHolderMessage ] = useMessage();

    api.toString()
    msg.toString();

    return(
        <>
            { location.pathname === LOGIN_PAGE || location.pathname === SIGNUP_PAGE ? 
                <>
                    {children}
                </>
                :
                <Layout>
                    {/* {width > 768 ? <SideBarLayout collapse = { collapse }/> : <DrawerMenu collapse = { collapse } setCollapse = {setCollapse}/>} */}
                    <Content
                        style = {{
                            display : "flex",
                            flexDirection : "column",
                            background : "white",
                            marginLeft : 0,
                            height : "100vh"
                        }}
                    >
                        <HeaderMenu path = {location.pathname === SHIPPING_PAGE ? "true" : "false"}/>
                        <ContainerWrapper path = {location.pathname === SHIPPING_PAGE ? "true" : "false"}>
                            {children}
                        </ContainerWrapper>
                        <Footer style = {{textAlign : "center"}}>© IEC - 2024. All rights reserved.</Footer>
                    </Content>
                </Layout>
            }
            { contextHolder }
            { contextHolderMessage }
        </>
    )
}

export default LayoutProvider;