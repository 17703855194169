import { createGlobalStyle } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';


const GlobalStyle = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        overflow-x: hidden;
        -webkit-tap-highlight-color: transparent;
        font-family : Source Sans Pro;
    }

    .ant-input,
    .ant-input-number{
        width : 100% !important;
        &:hover{
            border : 1px solid ${themeGet("primary.0")} !important;
            box-shadow : none;
        }
        &:focus{
            box-shadow : 0px 0px 0px 1px ${themeGet("primary.1")} !important;    
            border : 1px solid ${themeGet("primary.0")} !important;
        }
        box-shadow : none;
    }

    .ant-btn {
        &:hover{
            border : 1px solid ${themeGet("primary.0")} !important;
            color  : ${themeGet("primary.0")} !important;
            background-color : ${themeGet("color.1")} !important;
        }
        background-color : ${themeGet("primary.0")} !important;
        border : 1px solid ${themeGet("color.1")} !important;
        color : #fff !important;
        border-radius : 5px;
        
    }
    .cancelButton{
        background-color : ${themeGet("color.1")} !important;
        border : 1px solid ${themeGet("primary.0")} !important;
        color : ${themeGet("primary.0")} !important;
        &:hover{
            background-color : ${themeGet("primary.0")} !important;
            border : 1px solid ${themeGet("color.1")} !important;
            color : #fff !important;
        }
    }
    .dangerButton{
        background-color : ${themeGet("error.0")} !important;
        border : 1px solid ${themeGet("color.1")} !important;
        color : ${themeGet("color.1")} !important;
        &:hover{
            background-color : ${themeGet("color.1")} !important;
            border : 1px solid ${themeGet("error.0")} !important;
            color : ${themeGet("error.0")} !important;
        }
    }
    .ant-checkbox-wrapper {
        .ant-checkbox {
            flex-shrink: 0;
            .ant-checkbox-inner {
                border-radius: 0;
                border-color: ${themeGet('text.1', '#909090')};
            }
            &.ant-checkbox-checked {
                .ant-checkbox-inner {
                border-color    : ${themeGet('primary.0')};
                background-color: ${themeGet('primary.0')};
                }
            }
            &::after {
                display: none;
            }
        }
    }

    .ant-input, 
    .ant-input-number{
        border : 1px solid ${themeGet("primary.0")} !important;
        box-shadow : none !important;
    }
    .ant-select-selector{
        border : 1px solid ${themeGet("primary.0")} !important;
        box-shadow : none !important;
        &:hover {
            border : 1px solid ${themeGet("primary.0")} !important;
            box-shadow : 0px 0px 0px 1px ${themeGet("primary.1")} !important;
        }
    }

    .ant-select{
        width : 100%;
    }

    .ant-select-open{
        box-shadow : none !important;
        border-radius : 3px;
    }

    .ant-picker{
        border : 1px solid ${themeGet("primary.0")} !important;
        box-shadow : none !important;
    }

    .ant-picker-focused{
        border : 1px solid ${themeGet("primary.0")} !important;
        box-shadow : 0px 0px 0px 1px ${themeGet("primary.1")} !important;
    }

    .ant-switch{
        background : red !important;
    }

    .ant-switch-checked{
        background : green !important;
    }
    .ant-pagination-item,
    .ant-pagination-item-link {
        &:hover{
            a, svg {
                color  : ${themeGet("primary.0")} !important;
            }
            border : 1px solid ${themeGet("primary.0")} !important;
        }
    }
    .ant-pagination-item-active {
        border : 1px solid ${themeGet("primary.0")} !important;
        a {
        color  : ${themeGet("primary.0")} !important;
        }
    }
`;


export default GlobalStyle