import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get'


export const IconContainer = styled.div`
    display : flex;
    align-items : center;
    margin-right : 5px;
    svg{
        &:hover{
            color : ${themeGet("primary.0")};
        }
        height : 25px;
        width  : 25px;
        cursor: pointer;
    }
`;

export const TitleContainer = styled.div`
    display : flex;
    justify-content : flex-start;
    align-items : center;
    flex-grow : 1;
    .welcome{
        font-size : 1rem;
        font-weight : bold;        
    }
    .ie{
        color : #2c2877;
        font-weight : bold;
        font-size : 1rem;
        margin-left : 4px;
    }
    .cargo{
        color : #e84f0e;
        font-weight : bold;
        font-size : 1rem;
        margin-left : 4px;
    }
`;

export const ButtonLanguage = styled.button`
    padding : 5;
    display : flex;
    justify-content : center;
    align-items : center;
    background : #fff;
    cursor : pointer;
`

export const LabelOption = styled.div`
    padding : 10px;
    font-size : 14px;
    font-weight: bold;
`