import React, { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { SIGNUP_PAGE, HOME_PAGE, LOGIN_PAGE } from './settings/constants';
import { useContext } from 'react';
import { AuthContext } from './context/authProvider';
import LayoutProvider from './components/layout/layoutProvider';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

import global_es from './languages/ES/global.json';
import global_en from './languages/EN/global.json';
import ProtectedRoutes from './routes/protectedRoutes.routes';

i18next.init({
    interpolation : {
      escapeValue : false
    },
    lng : localStorage.getItem('language')!== null ? localStorage.getItem('language') : "es",
    resources : {
      es : {
        global : global_es
      },
      en : {
        global : global_en
      }
    }
})

const routes = [
    {
        path : LOGIN_PAGE,
        Component : lazy(()=>
            import('./components/auth/signIn/signIn')
        )
    },
    {
        path : SIGNUP_PAGE,
        Component : lazy(()=>
            import('./components/auth/signUp/signUp')
        )
    }
]

const Routing = () => {

    const { loggedIn } = useContext(AuthContext);

    return(
        <I18nextProvider i18n = {i18next}>
            <LayoutProvider>
                <Routes>
                    { routes.map(({ path, Component })=>(
                        <Route
                            path  = { path }
                            key = { path }
                            element = { loggedIn === false ?  <Component/> : <Navigate to = { HOME_PAGE }/> }
                        />
                    ))}

                    {
                        ProtectedRoutes.map(({ path, Component }) => {
                        return(
                            loggedIn ? 
                                <Route
                                    key  = { path }
                                    path = { path }
                                    element = { <Component/> }
                                />
                                :
                                <Route
                                    key  = { path }
                                    path = { path }
                                    element = { <Navigate to = { LOGIN_PAGE }/> }
                                />
                        )})
                    }

                </Routes>
            </LayoutProvider>
        </I18nextProvider>
    )
}


export default Routing;