import { useState, createContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useNotification from "antd/es/notification/useNotification";
import dayjs from "dayjs";

export const AuthContext = createContext();

const AuthProvider = (props) => {

    const history = useNavigate();
    const [ api ] = useNotification();
    const [ loggedIn, setLoggedIn ]     = useState(localStorage.getItem('userInfoIE') ? true : false);
    const [ userInfoIE, setUserInfoIE ] = useState(localStorage.getItem('userInfoIE') ? JSON.parse(localStorage.getItem('userInfoIE')) : {});
    const [ rqShippingFinished, setRqShippingFinished ] = useState({
        pageSize : 1000,
        fechaDesde : dayjs(new Date()).subtract(1,"month").format("YYYY-MM-DD"),
        fechaHasta : dayjs(new Date()).format("YYYY-MM-DD")
    })
    const [ rqTracking, setRqTracking ] = useState({
        "tipo_proceso": "TODOS",
        "pageSize": 1000,
        "fecha_inicial" : dayjs(new Date()).subtract(1,"month").format("YYYY-MM-DD"),
        "fecha_final" : dayjs(new Date()).format("YYYY-MM-DD")
    })

    const SignIn = ( userData ) => {
        // localStorage.setItem('tokenInterExpress', token.access_token);
        // localStorage.setItem('refreshTokenInterExpress', token.refresh_token);
        // setToken(token.access_token)
        // setRefreshToken(token.refresh_token)
        localStorage.setItem('userInfoIE', JSON.stringify(userData));
        setUserInfoIE(userData);
        setLoggedIn(true);
        history("/");
    }

    const updateData = ( userData ) => {
        const data = {...userData, token : userInfoIE.token, refreshToken : userInfoIE.refreshToken }
        localStorage.setItem('userInfoIE', JSON.stringify(data));
        setUserInfoIE(data);
        history(0);
    }

    const logOut = () => {
        localStorage.removeItem('tokenInterExpress');
        localStorage.removeItem('refreshTokenInterExpress');
        localStorage.removeItem('userInfoIE');
        setLoggedIn(false);
        setUserInfoIE({});
        history("/");
    };

    const refreshTokenF = ( newToken, newRefreshToken ) => {
        const data = {...userInfoIE, token : newToken, refreshToken : newRefreshToken }
        localStorage.setItem('userInfoIE', JSON.stringify(data));
        setUserInfoIE(data);
        history(0);
    }

    const refreshTokenAction = async () => {
        const result = await axios.post(`https://iec.helgasys.com/oauth/token`,
            {
                "grant_type" : "refresh_token",
                "client_id" : 2,
                "client_secret" : process.env.REACT_APP_CLIENT_SECRET,
                "refresh_token" : userInfoIE.refreshToken
            }
        )
        .catch((error)=>{
            return error.response ? error.response : { error : error }
        })

        if(result.status === 200){
            const info = {...userInfoIE, token : result.data.access_token, refreshToken: result.data.refresh_token }
            setUserInfoIE(info);
            localStorage.setItem('userInfoIE', JSON.stringify(info));
        }
        else{
            api.error({
                message : "Error",
                description : result.data.message
            })
            logOut()
        }
    }

    return(
        <AuthContext.Provider
            value = {{
                loggedIn, setLoggedIn,
                SignIn, logOut,
                userInfoIE, setUserInfoIE,
                rqShippingFinished, setRqShippingFinished,
                rqTracking, setRqTracking,
                updateData,
                refreshTokenAction,
                refreshTokenF
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}


export default AuthProvider;