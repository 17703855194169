import styled, { css } from 'styled-components';


export const ContainerWrapper = styled.div`
    margin-left: auto;
    margin-right: auto;
    ${(props)=>
        props.isheader !== "true" &&
        css`
            background : #fff;
            flex-grow : 1;
            margin-top : 16px;
            margin-bottom : 16px;
            padding : 20px;
            border : 1px solid #f2f2f2;
        `
    }

    @media (max-width: 768px) {
        margin-left : 24px;
        margin-right : 24px;
        max-width: 95%;
        ${(props)=>
            props.isheader === "true" &&
            css`
                margin-left : 0px;
                margin-right : 0px;
                max-width: 100%;
            `
        }
    }

    @media (min-width: 768px) {
        max-width: 750px;
        width: 100%;
    }

    @media (min-width: 992px) {
        max-width: 970px;
        width: 100%;
    }

    @media (min-width: 1200px) {
        ${(props)=> 
            props.path === "true" && css`
                max-width: 90vw;
            `
        }
        ${(props)=> 
            props.path !== "true" && css`
                max-width: 1200px;
            `
        }
        width: 100%;
    }
`;