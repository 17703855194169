import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from 'antd/lib/layout/layout';
import { IconContainer, LabelOption, TitleContainer } from './headerStyles';
import useWindowSize from './useWindowSize';
import { Dropdown, Avatar } from 'antd';
import userImage from '../../assets/images/profileImage.jpg'
import { AuthContext } from '../../context/authProvider';
import { FaHome } from 'react-icons/fa'
import { ContainerWrapper } from './layoutStyle';
import { useTranslation } from 'react-i18next';
import ChangeLanguage from './language';

const HeaderMenu = ({ path }) => {

    const { width }  = useWindowSize()
    const { logOut, userInfoIE } = useContext(AuthContext)
    const navigate    = useNavigate()
    const { t } = useTranslation("global");

    const items = [
        {
          label:(
                    <LabelOption>
                        {t("menuUser.changePassword")}
                    </LabelOption>
                ),
          key: '0',
        },
        {
          label:(
                    <LabelOption onClick ={()=> logOut()}>
                        {t("menuUser.logOut")}
                    </LabelOption>
                ),
          key: '1',
        },
        {
          type: 'divider',
        },
        {
          label: <ChangeLanguage isMenu = { true }/>,
          key: '3',
        },
      ];

    return(
        <ContainerWrapper isheader = "true" path = { path.toString() } >
          <Header
            style = {{ 
                display    : "flex",
                background : "#fff",
                borderBottom  : "1px solid #f2f2f2",
                height : 70,
                alignItems : "center",
                padding : 16
            }}
        >
            <IconContainer
                onClick = { ()=> navigate('/')}
            >
                <FaHome/>
            </IconContainer>
            <TitleContainer>
                <span className = "welcome">
                    {`${t("header.welcome")} ${userInfoIE.codigo_casillero}`}
                    { width > 768 &&
                        <>
                            <span className = "ie">INTEREXPRESS</span> 
                            <span className = "cargo">CARGO</span>
                        </> 
                    }
                </span>
            </TitleContainer>
            
            <Dropdown menu={{ items,}} trigger = {["click"]}>
                <div
                    style = {{
                        cursor : "pointer"
                    }}
                >
                    { width > 768 &&
                        <span
                            style = {{
                                marginRight : 10
                            }}
                        >
                            { userInfoIE.nombre_completo }
                        </span>
                    }
                    <Avatar
                        size = { 40 }
                        icon = { 
                            <img
                                src = { userImage }
                                alt = ""
                                style = {{
                                    objectFit : "contain",
                                    width : 40,
                                    height : 40
                                }}
                            />
                        }
                    >

                    </Avatar>
                </div>
            </Dropdown>
            
        </Header>
        </ContainerWrapper>
    )
}


export default HeaderMenu;