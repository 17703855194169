import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonLanguage } from './headerStyles';
import { iconES, iconEN } from '../../languages/icons/index';
import { useNavigate } from 'react-router-dom';


const ChangeLanguage = ({ isMenu }) => {
 
    const { i18n } = useTranslation("global")
    const navigate    = useNavigate()

    const changeLanguage = ( lang ) => {
        localStorage.removeItem('language');
        i18n.changeLanguage(lang);
        localStorage.setItem('language', lang)
        navigate(0)
    }

    return(
        <div
            style = {{
                display : "flex",
                justifyContent : "center"
            }}
        >
            <ButtonLanguage
                style = {{
                    border : i18n.language === "es" ? "1px solid red" : "none",
                    marginRight : 10
                }}
                onClick = {()=> changeLanguage("es")}
            >
                <img
                    src = { iconES }
                    alt = ""
                    style = {{
                        height : 35,
                        width  : 35
                    }}
                />
            </ButtonLanguage>
            <ButtonLanguage
                style = {{
                    border : i18n.language === "en" ? "1px solid red" : "none"
                }}
                onClick = {()=> changeLanguage("en")}
            >
                <img
                    src = { iconEN }
                    alt = ""
                    style = {{
                        height : 35,
                        width  : 35
                    }}
                />
            </ButtonLanguage>
        </div>
    )
}

export default ChangeLanguage;