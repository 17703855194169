import { lazy } from "react";
import { ACCOUNTSTATUS_PAGE, HOME_PAGE, PREALERTS_PAGE, PROFILE_PAGE, RECIPIENTS_PAGE, SHIPPING_PAGE, TRACKING_PAGE } from "../settings/constants";

const ProtectedRoutes = [
    {
        Component : lazy(()=> import('../components/home/home')),
        path : HOME_PAGE
    },
    {
        Component : lazy(()=> import('../components/casillero/profile')),
        path : PROFILE_PAGE
    },
    {
        Component : lazy(()=> import('../components/prealertas/prealertas')),
        path : PREALERTS_PAGE
    },
    {
        Component : lazy(()=> import('../components/estadocuenta/estadoCuenta')),
        path : ACCOUNTSTATUS_PAGE
    },
    {
        Component : lazy(()=> import('../components/despachos/solicitarDespacho')),
        path : SHIPPING_PAGE
    },
    {
        Component : lazy(()=> import('../components/destinatarios/destinatarios')),
        path : RECIPIENTS_PAGE
    },
    {
        Component : lazy(()=> import('../components/rastrearEnvio/rastrearEnvio')),
        path : TRACKING_PAGE
    },
    {
        Component : lazy(()=> <>Error</>),
        path : "/*"
    },
]

export default ProtectedRoutes;