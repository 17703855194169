import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './assets/style/globalStyle';
import AuthProvider from './context/authProvider';
import reportWebVitals from './reportWebVitals';
import theme from './themes/defaultTheme';
import Routes from './router';
import { HelmetProvider } from 'react-helmet-async';

const App = () => {

  return(
    <HelmetProvider>
      <ThemeProvider theme = { theme }>
        <>
          <GlobalStyle/>
          <Suspense
            fallback = { null }
          >
            <BrowserRouter>
              <AuthProvider>
                <Routes/>
              </AuthProvider>
            </BrowserRouter>
          </Suspense>
        </>
      </ThemeProvider>
    </HelmetProvider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(<App />);

reportWebVitals();
